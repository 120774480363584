/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import { url_path, check, register, copyrightLabel } from "./../extension/exportedStrings";

import { validateUsername } from "./../extension/inputValidity";
import { createAcc } from "./../extension/registerAccount";

import FooterComponent from "./Footer.js";

import "./../style/create.css";

import { googleLoginSecretKey } from "./../extension/exportedStrings";
function CreateThirdParty() {
  const navigate = useNavigate();
  const location = useLocation();

  const [button, setButton] = useState("Create account");

  var fullnameValue = "";
  var emailValue = "";
  var usernameValue = "";
  var valid = false;

  useEffect(() => {
    document.title = "SociRank - Register"; 

    const currentPath = location.pathname;

    const part = currentPath.split("/");
    const base64EncodedData = part[2];
    const secretKey = googleLoginSecretKey; // Replace with your secret key
    // Decode the Base64 data
    const encryptedData = atob(base64EncodedData);

    // Decrypt the data with AES decryption
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);

    // Convert the decrypted bytes to a string
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

    const infoSplit = decryptedData.split(" ");

    emailValue = infoSplit[0];
    fullnameValue = infoSplit[1];
  }, []);

  const detailCheck = async (value, type) => {
    if(type == "username"){
        if(!validateUsername(value)){
           const item = document.getElementById(type);
           item.style.borderColor = "red";

           return;
        }
    }

    const url =  url_path + check; 
    const data = {
      username: value, // Replace with the actual username
      type: type, 
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // Handle the response data here
        if(data == "success"){
          const item = document.getElementById(type);
          // Change the border color to red
          item.style.borderColor = "#AFE1AF";
          usernameValue = value.trim();
          valid = true
        }else{
          const item = document.getElementById(type);
          // Change the border color to red
          item.style.borderColor = "red";

          valid = false
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
        valid = false
      });
  };

  const createAccount = async () => {
    if (valid) {
       setButton("Creating account...")

       const url = url_path + register; 

       createAcc(url, usernameValue, fullnameValue, emailValue, "", "")

       const authenticationData = {
        username: usernameValue,
      };

      // Save the data to localStorage
      localStorage.setItem('currentUser', JSON.stringify(authenticationData));

      let path = `/`;
      navigate(path);
      window.location.reload();
    }
  }

  return (
    <div>
      <div className="createBackground">
        <div className="divBox">
          <img src={app_icon2} className="divImage" width="25" height="25" />
          <p className="createLabel">Choose username</p>
          <input
            className="createInput"
            id="username"
            name="username"
            placeholder="Enter username"
            onBlur={(e) => detailCheck(e.target.value, "username")}
          ></input>

          <p className="createTosLabel">
            By clicking register, you agree that you have read and accepted the
            <a href="/terms-condition" className="link" target="_blank">
              {" "}
              Terms of Use
            </a>
          </p>
          <button class="createButton" onClick={createAccount}>
            {button}
          </button>
        </div>
        <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
      </div>
      </div>
    </div>
  );
}

export default CreateThirdParty;
