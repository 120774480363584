/** @format */
import React, { useEffect, useState } from "react";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import "./../style/TermsOfService.css";
import FooterComponent from "./Footer.js";

const TermOfService = () => {
  useEffect(() => {
    document.title = "SociRank - Terms Of Use"; // Change the title here
  }, []);
  return (
    <div>
      <div className="tosBackground">
        <div className="tosHeader">
          <img src={app_icon2} className="Image" width="25" height="25" />
          <p className="tosHeaderLabel">Terms Of Use</p>
        </div>

        <div className="tosDiv">
          <p className="tosDivLabel">Understanding Our Data Practices</p>
          <p className="tosDivBody">
            At SociRank, transparency and clarity regarding your data are
            paramount. We believe that you should have a comprehensive
            understanding of how your data is collected, utilized, and how you
            can exercise control over it. To ensure this, we have provided
            extensive information in our Privacy Policy and Terms of Service.
            These documents delve into the specifics of our data practices and
            your rights. To offer you a more detailed perspective, here are some
            key highlights:
          </p>
          <p className="tosDivBody">
            <strong>Data Collection:</strong> Our platform collects specific
            types of data necessary for its functioning. This may include
            personal information, usage data, and more. We collect this data
            through various means, such as user input and automated processes.
          </p>
          <p className="tosDivBody">
            <strong>Data Utilization:</strong> Your data is used to enhance your
            experience on SociRank. This includes personalizing content,
            improving our services, and providing relevant recommendations. We
            take your privacy seriously and do not share your data with third
            parties without your consent.
          </p>
          <p className="tosDivBody">
            <strong>Data Control:</strong> We believe you should have control
            over your data. Our platform provides options for you to manage your
            data preferences. You can review, edit, or delete your information
            as needed.
          </p>
          <p className="tosDivBody">
            <strong>Security Measures:</strong>We have implemented robust
            security measures to protect your data from unauthorized access and
            breaches. Your privacy and data security are our top priorities.
          </p>
        </div>

        <div className="tosDiv">
          <p className="tosDivLabel">Personal Information</p>
          <p className="tosDivBody">
            Your personal information is valuable, and we want you to have full
            control over it. Until the time you decide to remove your account,
            rest assured that we'll securely store your data. This includes not
            only the videos you've shared, your profile photo, and any audio
            content you've uploaded – all of which are rightfully yours – but
            also the details necessary for our platform to function seamlessly.
            We take data privacy seriously, and you can find more details on how
            we handle and protect your information in our Privacy Policy.
          </p>
        </div>

        <div className="tosDiv">
          <p className="tosDivLabel">Payment and Purchase</p>
          <p className="tosDivBody">
            We prioritize the security of your financial information. When you
            add your payment details, it's important to know that we do not
            store your credit card information in any database. This means that
            even in the unlikely event of a security breach, your sensitive
            financial data remains inaccessible to any third party.
          </p>
          <p className="tosDivBody">
            Furthermore, we believe in fair and transparent transactions. That's
            why we offer a refund policy that allows you to request a refund as
            long as the purchase date is within 14 days. Your satisfaction and
            peace of mind are important to us, and we want you to have
            confidence in your transactions with us.
          </p>
        </div>
        <div className="tosDiv">
          <p className="tosDivLabel">User Generated Content</p>
          <p className="tosDivBody">
            We have strict content guidelines in place to ensure a safe and
            respectful environment for all users. Our policies prohibit the
            sharing of content that involves violence, nudity, or any actions
            that infringe upon the privacy of others.
          </p>
          <p className="tosDivBody">
            Additionally, when it comes to sharing content that is not your own,
            we expect users to either obtain proper consent from the owner or
            provide appropriate credit to the original creator. Failure to
            adhere to these guidelines may result in the removal of
            non-compliant content.
          </p>
          <p className="tosDivBody">
            At SociRank, we take these rules seriously to maintain a positive
            and respectful community for everyone.
          </p>
        </div>
      </div>
      <div className="tosFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel2">
            {copyrightLabel}
          </p>
      </div>
    </div>
  );
};

export default TermOfService;
