/** @format */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import { app_icon, soci_coin } from "./../extension/exportedIcons";
import { top_icon, hoodie_icon, pants_icon } from "./../extension/merchImg";

import { getCoinValue } from "./../data/coinData";
import { cashName, cashPrice, cashAmount } from "./../data/cashArray";
import { copyrightLabel } from "./../extension/exportedStrings";

import "./../style/home.css";
const Home = () => {
  const navigate = useNavigate();
  const [coinValue, setCoinValue] = useState("0");
  useEffect(() => {
    document.title = "SociRank - Store"; // Change the title here
    
    getCoinValue.then(result => {
      setCoinValue(result)
    });
  }, []);

  const goExchange = (index) => {
    let path = `/exchange/${index}`;
    navigate(path);
  }

  return (
    <div>
        <MinibarComponent />
        <div className="coinDiv">
          <img src={soci_coin} className="coinImg" width="30" height="30" />
          <p className="coinLabel">{coinValue}.00</p>
        </div>
        <div className="merchDiv">
          <div className="merchItem">
           <p className="merchLabel">SociTop</p>
            <img src={top_icon} className="merchImg" />
            <div className="merchView"></div>
            <p className="merchLabel2">$5.00 or 5000 SociCoin</p>
            <button className="merchButton">Coming soon</button>
          </div>
          <div className="merchItem">
           <p className="merchLabel">SociHoodie</p>
            <img src={hoodie_icon} className="merchImg" />
            <div className="merchView"></div>
            <p className="merchLabel2">$15.00 or 15000 SociCoin</p>
            <button className="merchButton">Coming soon</button>
          </div>
        </div>

        <p className="cashTitle">Exchange SociCoins for Real-Life currency</p>

        <div className="cash-container" id="cashContainer">
            {cashName.map((array, index) => (
              <div className="cashTable" onClick={() => goExchange(index)}>
                <p className="cashLabel">{cashAmount[index]}</p>
                <img src={array} className="cashImg" alt="Image Description" />
                <div className="cashView"></div>

                <div className="priceDiv">
                  <img src={soci_coin} className="coinImg2" width="30" height="30" />
                  <p className="cashLabel2">{cashPrice[index]}</p>
                </div>
              </div>
            ))}
          </div>

        <div className="homeFooterDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={app_icon}
              alt="Image"
            />
            <FooterComponent />
           </div>

          <p className="copyrightLabel2">
            {copyrightLabel}
          </p>
        </div>
    </div>
  );
};

export default Home;
