import { url_link } from "./exportedStrings";
import { profile_icon } from "./exportedIcons";

export const checkProfileExist = async (value) => {
    
  const imageSrc = url_link + "images/" + value + ".png";
  try {
    // Try to fetch the image
    const response = await fetch(imageSrc, { method: "HEAD" });

    // Check if the image exists (status code 200)
    if (response.status === 200) {
      return imageSrc; // Return the image source
    } else {
      return profile_icon; // Return the default image
    }
  } catch (error) {
    console.error("Error checking profile image:", error);
    return profile_icon; // Return the default image in case of an error
  }
};
