/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import { validateEmail, validateNumber } from "./../extension/inputValidity";
import { copyrightLabel } from "./../extension/exportedStrings";
import { generateUUID } from "./../extension/generateUUID";

import { addApplication, addToStorage } from "./../firebase/applyFirebase.js";

import { sendEmail } from "./../data/sendEmail";
import { jobArray } from "./../data/jobArray";

import "./../style/application-confirm.css";
import FooterComponent from "./Footer.js";
const ApplicationConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [jobData, setJobData] = useState([]);
  const [responsibilityArray, setResponsibilityArray] = useState([]);
  const [requirementArray, setRequirementArray] = useState([]);
  const [offerArray, setOfferArray] = useState([]);

  const [resumeURL, setResumeURL] = useState("");
  const [resumeLabel, setResumeLabel] = useState("Select your resume");
  const [fileLabel, setFileLabel] = useState(
    "Choose a .pdf, .doc, .docx, .odt, or .rtf file"
  );
  useEffect(() => {
    document.title = "SociRank - Application";

    const currentPath = location.pathname;

    const part = currentPath.split("/");
    const decodedParameter = part[2];
    const jobTitle = decodeURIComponent(decodedParameter);

    // Find the job object with the matching title in jobArray
    const selectedJob = jobArray.find((job) => job.title === jobTitle);

    // If a matching job is found, you can set it in the state
    if (selectedJob) {
      setJobData(selectedJob);
      setResponsibilityArray(selectedJob.keyResponsibilities);
      setRequirementArray(selectedJob.requirements);
      setOfferArray(selectedJob.whatWeOffer);
    }
  }, []);

  const selectFile = () => {
         // Create an invisible file input element
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".pdf,.doc,.docx,.odt,.rtf";

    // Handle file selection
    fileInput.addEventListener("change", (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        // You can now handle the selected file as needed.
        setResumeURL(selectedFile)
        setResumeLabel(selectedFile.name)
        
        const input = document.getElementById("resumeDiv")
        input.style.borderColor = "#e3e3e3";
      }
    });

    // Trigger the file input dialog
    fileInput.click();
  };

  const confirmApplication = () => {
    const resumeInput = document.getElementById("resumeDiv")
    const fullnameInput = document.getElementById("fullnameInput")
    const emailInput = document.getElementById("emailInput")
    const numberInput = document.getElementById("numberInput")

    if(resumeLabel == "Select your resume"){
        resumeInput.style.borderColor = "red";
        return;
    }
    if(fullnameInput.value.trim() == ""){
        fullnameInput.style.borderColor = "red"
        return;
    }
    if(emailInput.value.trim() == "" || !validateEmail(emailInput.value.trim())){
        emailInput.style.borderColor = "red"
        return;
    }
    if(!validateNumber(numberInput.value.trim())){
        numberInput.style.borderColor = "red"
        return;
    }

    const uniqueID = generateUUID();
    addToStorage(resumeURL, uniqueID).then((result) => {
      addApplication(result, jobData.title, uniqueID, fullnameInput.value.trim(), emailInput.value.trim(), numberInput.value.trim())
      .then((data) => {
        sendEmail(emailInput.value.trim(), jobData.title, fullnameInput.value.trim());
      })
      .catch((error) => {
        console.error(error);
      });
    })
    .catch((error) => {
      console.error(error);
    });

    let path = `/application-confirmed`;
    navigate(path);
  }

  return (
    <div>
      <div className="applicationLeft">
        <p className="applicationLeftLabel">{jobData.title}</p>
        <p className="applicationLeftLabel2">{jobData.description}</p>
        <div className="applicationJobDiv">
          <p className="applicationJobLabel">Key responsibilities</p>
          {responsibilityArray.map((responsibility, index) => (
            <p className="applicationTableLabel">- {responsibility}</p>
          ))}

          <p className="applicationJobLabel">Requirements</p>
          {requirementArray.map((requirements, index) => (
            <p className="applicationTableLabel">- {requirements}</p>
          ))}

          <p className="applicationJobLabel">What we offer</p>
          {offerArray.map((offer, index) => (
            <p className="applicationTableLabel">- {offer}</p>
          ))}
        </div>
      </div>
      <div className="applicationRight">
        <div className="applicationRightDiv" id="resumeDiv"
         onClick={selectFile}>
          {resumeLabel}
        </div>
        <p className="fileLabel" onClick={selectFile}>
          {fileLabel}
        </p>
        <p className="resumeJobLabel">Fullname</p>
        <input
          className="resumeInput"
          id="fullnameInput"
          name="username"
          placeholder="Enter your fullname"
          onBlur={(e) => e.target.style.borderColor = "#e3e3e3"}
        ></input>
        <p className="resumeJobLabel">Email</p>
        <input
          className="resumeInput"
          id="emailInput"
          name="username"
          placeholder="Enter your email"
          onBlur={(e) => e.target.style.borderColor = "#e3e3e3"}
        ></input>
        <p className="resumeJobLabel">Phone Number</p>
        <input
          className="resumeInput"
          id="numberInput"
          name="username"
          placeholder="Enter your phone number"
          onBlur={(e) => e.target.style.borderColor = "#e3e3e3"}
        ></input>
        <button id="resumeButton" class="resumeButton" onClick={confirmApplication}>
          Submit your application
        </button>
      </div>

      <div className="footerDiv2">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default ApplicationConfirm;
