import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';

import Maintenance from './design/Maintenance';
import FailedLogin from './design/FailedLogin';
import ForgotPassword from './design/ForgotPassword';
import Cashout from './design/Cashout';
import Profile from './design/Profile';
import Application from './design/Application';
import ApplicationConfirm from './design/ApplicationConfirm';
import ApplicationConfirmed from './design/ApplicationConfirmed';
import SettingsView from './design/SettingsView';
import Notification from './design/Notification';
import Checkout from './design/Checkout';
import Coin from './design/Coin';
import Login from './design/Login';
import Home from './design/Home';
import Register from './design/Register';
import RegisterInvite from './design/RegisterInvite';
import CreateThirdParty from './design/CreateThirdParty';
import TermsOfService from './design/TermsOfService';
import AboutUs from './design/AboutUs';
import Manage from './design/Manage';
import OrderManage from './manage/OrderManage'
import DigitalGood from './manage/DigitalGood'
import Error from './design/Error';

// Check if there's authentication data in localStorage
const authenticationData = JSON.parse(localStorage.getItem('currentUser'));
const isAuthenticated = authenticationData && authenticationData.username;

function App() {
  return (
    <Router>
      <div className="App">
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/" element={<Home />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
          </>
        )}
        <Route path="/exchange/:data" element={<Cashout />} />
        <Route path="/setting" element={<SettingsView />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/digital-good" element={<DigitalGood />} />
        <Route path="/manage-order" element={<OrderManage />} />
        <Route path="/manage" element={<Manage />} />
        <Route path="/coin" element={<Coin />} />
        <Route path="/login/:data" element={<FailedLogin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/:data" element={<Profile />} />
        <Route path="/application" element={<Application />} />
        <Route path="/confirm-application/:data" element={<ApplicationConfirm />} />
        <Route path="/application-confirmed" element={<ApplicationConfirmed />} />
        <Route path="/invite/:data" element={<RegisterInvite />} />
        <Route path="/create-account/:data" element={<CreateThirdParty />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/terms-condition" element={<TermsOfService />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  </Router>

  );
}

export default App;

{/* <Routes>
{isAuthenticated ? (
  <>
    <Route path="/login" element={<Navigate to="/" />} />
    <Route path="/" element={<Home />} />
  </>
) : (
  <>
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<Navigate to="/login" />} />
  </>
   </Routes>
)} */}
