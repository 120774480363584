import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Error() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const goHome = () => {
        let path = `/`;
        navigate(path);
    }
    
    goHome(); 
  }, []);

  return (
    <div className="Error">
        
    </div>
  );
}

export default Error;