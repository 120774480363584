import { database, ref, set, onValue, remove, storage, downloadUrl, bytes, sRef } from './firebase';

export const addApplication = (resume, type, resumeName, fullname, email, phonenumber) => {
  const resumeRef = ref(database, `resume/${fullname}`);
  
  const newData = {
    resume: resume,
    type: type,
    resumeName: resumeName,
    fullname: fullname,
    email: email,
    phonenumber: phonenumber
  };
  
  return set(resumeRef, newData);
};


export const addToStorage = async (selectedFile, uniqueID) => {
  const storageRef = sRef(storage, 'resumes/' + uniqueID);

  try {
    // Upload the selected file to Firebase Storage
    await bytes(storageRef, selectedFile);

    // Get the download URL of the uploaded file
    const downloadURL = await downloadUrl(storageRef);

    // You can store the download URL in your Firebase database or use it as needed
    return downloadURL;
  } catch (error) {
    return false;
  }
}
