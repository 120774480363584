/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { userAuthenticated } from "./../extension/currentUser";

import { url_path, deletePath } from "./../extension/exportedStrings";

import "./../style/delete-settings.css";

const DeleteSetting = () => {
  const navigate = useNavigate();
  
  const [reasons, setReasons] = useState({
    problem1: false,
    problem2: false,
    problem3: false,
    problem4: false,
    problem5: false,
  });

  const handleCheckboxChange = (problem) => {
    setReasons((prevReasons) => {
      const updatedReasons = {
        ...prevReasons,
        [problem]: !prevReasons[problem],
      };

      return updatedReasons;
    });
  };

  const handleDeleteAccount = () => {
    var problem1 = reasons["problem1"];
    var problem2 = reasons["problem2"];
    var problem3 = reasons["problem3"];
    var problem4 = reasons["problem4"];
    var problem5 = reasons["problem5"];

    if (!(problem1 || problem2 || problem3 || problem4 || problem5)) return;
    const deleteInput = document.getElementById("deleteInput").value.trim();

    const url = url_path + deletePath;

    // Assuming 'reasons' is an object with keys like 'problem1', 'problem2', etc.
    const requestBody = {
      username: userAuthenticated,
      problem1: problem1,
      problem2: problem2,
      problem3: problem3,
      problem4: problem4,
      problem5: problem5,
      problem6: "", // Assuming 'problem6' is always an empty string based on your previous code
      explanation: deleteInput,
    };

    // Convert the request body to a JSON string
    const jsonBody = JSON.stringify(requestBody);

    // Make the HTTP POST request using the fetch API
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonBody,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data
        localStorage.removeItem("currentUser");
    
        let path = `/login`;
        navigate(path, { replace: true });
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        localStorage.removeItem("currentUser");
    
        let path = `/login`;
        navigate(path, { replace: true });
      });
  };

  return (
    <div>
      <h1 id="deleteLabel" className="deleteLabel">
        Reason for deleting account
      </h1>

      <div className="optionDiv">
        <label className="deleteContainer">
          I have a different account
          <input
            type="checkbox"
            checked={reasons.problem1}
            onChange={() => handleCheckboxChange("problem1")}
          />
          <span className="deleteCheckmark"></span>
        </label>

        <label className="deleteContainer">
          I spend too much time on the app
          <input
            type="checkbox"
            checked={reasons.problem2}
            onChange={() => handleCheckboxChange("problem2")}
          />
          <span className="deleteCheckmark"></span>
        </label>

        <label className="deleteContainer">
          I don't feel safe using the app
          <input
            type="checkbox"
            checked={reasons.problem3}
            onChange={() => handleCheckboxChange("problem3")}
          />
          <span className="deleteCheckmark"></span>
        </label>

        <label className="deleteContainer">
          I didn't have a good experience using the app
          <input
            type="checkbox"
            checked={reasons.problem4}
            onChange={() => handleCheckboxChange("problem4")}
          />
          <span className="deleteCheckmark"></span>
        </label>

        <label className="deleteContainer">
          I need a break from social media
          <input
            type="checkbox"
            checked={reasons.problem5}
            onChange={() => handleCheckboxChange("problem5")}
          />
          <span className="deleteCheckmark"></span>
        </label>

        <h1 id="deleteLabel2" className="deleteLabel2">
          In order for us to provide a better experience for other users, please
          do further state your reason (Optional)
        </h1>
        <textarea
          id="deleteInput"
          className="deleteInput"
          placeholder="Explanation"
          maxLength="200"
        ></textarea>

        <button
          id="deleteButton"
          className="deleteButton"
          onClick={handleDeleteAccount}
        >
          Delete account
        </button>
      </div>
    </div>
  );
};

export default DeleteSetting;
