/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { generateStars, recordText } from "./../extension/generateStars";

import { app_icon2, profile_icon } from "./../extension/exportedIcons";
import { checkProfileExist } from "./../extension/setProfileIcon";

import { checkPages } from "./../extension/checkPages";
import { url_link } from "./../extension/exportedStrings";

import { handleButtons } from "./../extension/arrowNavigation";
import { handleClickOutside } from "./../extension/mouseNavigation";
import "./../style/search.css";

const SearchText = ({ isOpen, setIsOpen, searchRef, listArray, isLoading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const textRef = useRef(null);
  const currentPath = location.pathname.slice(1);

  useEffect(() => {
    const onClickOutside = (event) => {
      handleClickOutside(event, searchRef, setIsOpen);
    };

    if (isOpen) {
      document.addEventListener("mousedown", onClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [isOpen, setIsOpen, searchRef]);

  useEffect(() => {
    const eventHandler = (event) => {
      handleButtons(
        event,
        hoveredIndex,
        listArray,
        setIsOpen,
        setHoveredIndex,
        goProfile,
        textRef
      );
    };

    window.addEventListener("keydown", eventHandler);

    return () => {
      window.removeEventListener("keydown", eventHandler);
    };
  }, [hoveredIndex, listArray, setIsOpen]);

  const goProfile = (value) => {
    if (checkPages(currentPath)) {
      let path = `/${value}`;
      navigate(path);
      window.location.reload();
    } else {
      let path = `/${value}`;
      navigate(path);
    }
  };

  return (
    <div>
      <div
        className={isOpen ? "tableDiv" : "hidden"}
        ref={textRef}
        tabIndex={0}
      >
        {!isLoading ? (
          <table className="searchTable">
            <tbody>
              {listArray.map((array, index) => (
                <div
                  className="fullWidthTable"
                  tabIndex={0}
                  style={{
                    backgroundColor:
                      index === hoveredIndex ? "#e3e3e3" : "white",
                  }}
                  onClick={() => {
                    goProfile(array.username);
                  }}
                  onMouseEnter={() => {
                    setHoveredIndex(index);
                  }}
                >
                  <tr className="searchRow">
                    <div className="searchDiv">
                      <img
                        className="searchIcon"
                        src={`${url_link}images/${array.username}.png`}
                        onError={(e) => {
                          if (e.target.src !== profile_icon) {
                            e.target.onerror = null;
                            e.target.src = profile_icon;
                          }
                        }}
                      ></img>
                      <div className="searchTextDiv">
                        <p className="searchName">{array.username} - shop</p>
                        <p className="searchHobby"> {generateStars(5)}</p>
                      </div>
                    </div>
                  </tr>
                </div>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="loading-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchText;
