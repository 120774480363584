import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, onValue, remove, update} from 'firebase/database';
import { getStorage, ref as sRef, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  databaseURL: "https://project-326605-default-rtdb.asia-southeast1.firebasedatabase.app",
  storageBucket: "gs://project-326605.appspot.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
const storage = getStorage(app);
const downloadUrl = getDownloadURL;
const bytes = uploadBytes
export {app,database, ref, set, get, update, onValue, remove, storage, downloadUrl, bytes, sRef};