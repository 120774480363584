/** @format */
import React, { useEffect, useRef } from "react";

import "./../style/biodialog.css";

const BioDialog = ({ text, isOpen, setIsOpen }) => {
  const bioDivRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (bioDivRef.current && !bioDivRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    const closeDialog = () => {
      setIsOpen(false);
      document.body.style.overflowY = "auto";
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div>
      <div className={isOpen ? "bioDiv" : "hidden"} ref={bioDivRef}>
        <p className="bioDivLabel">
          {text}
        </p>
      </div>
    </div>
  );
};

export default BioDialog;
