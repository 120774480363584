export const jobArray = [
    {
        title: "Social Media Manager",
        description: "SociRank is seeking a talented and creative Social Media Manager to join our team. As a Social Media Manager, you will play a crucial role in shaping our online presence, driving engagement, and enhancing brand visibility across various social media platforms. This role offers a unique opportunity to work with a forward-thinking company that values work-life balance and offers competitive compensation.",
        keyResponsibilities: [
          "Create and curate engaging content for various social media platforms, including but not limited to Facebook, Instagram, Twitter, and LinkedIn.",
          "Develop and implement effective social media marketing strategies to boost brand awareness and drive user engagement.",
          "Monitor and analyze social media metrics to track the effectiveness of marketing campaigns.",
          "Interact with our online community, respond to comments and inquiries, and build lasting relationships with our audience.",
          "Collaborate with cross-functional teams to align social media efforts with overall marketing and business objectives.",
          "Stay updated on industry trends and best practices in social media marketing."
        ],
        requirements: [
          "Having passion and belief that you will succeed in your role.",
          "Excellent written and verbal communication skills.",
          "Strong understanding of social media platforms, algorithms, and trends.",
          "Creative mindset with a passion for storytelling.",
          "Ability to work independently and efficiently within a flexible, 4-hour daily schedule.",
          "Highly organized and detail-oriented."
        ],
        whatWeOffer: [
          "Competitive salary and benefits package.",
          "Opportunity to be part of an innovative tech start-up.",
          "Flexible work hours (4 hours per day).",
          "A collaborative and supportive work environment.",
          "Professional development and growth opportunities."
        ]
      }
      ,
      {
        title: "Growth Marketer",
        description: "SociRank is searching for a dynamic and results-driven Growth Marketer to join our team. As a Growth Marketer, you will be a key player in driving our company's growth initiatives, expanding our user base, and optimizing our marketing strategies. This role offers a unique opportunity to work with a forward-thinking company that values work-life balance and offers competitive compensation.",
        keyResponsibilities: [
          "Develop and execute creative marketing strategies to drive user acquisition and retention.",
          "Analyze data and user behavior to identify growth opportunities and optimize marketing campaigns.",
          "Create and manage performance marketing campaigns across various digital channels.",
          "Collaborate with the product and engineering teams to implement growth-focused product improvements.",
          "Use A/B testing and data-driven decision-making to enhance conversion rates and user engagement.",
          "Stay updated on industry trends and emerging growth marketing techniques."
        ],
        requirements: [
          "Proven experience in growth marketing, user acquisition, or a related field.",
          "Strong analytical and data-driven mindset.",
          "Proficiency in marketing tools and platforms, including SEO, SEM, and social media advertising.",
          "Excellent communication and collaboration skills.",
          "A passion for innovation and a drive to deliver results.",
          "Ability to work effectively in a dynamic and fast-paced environment."
        ],
        whatWeOffer: [
          "Competitive salary and benefits package.",
          "Opportunity to be part of an innovative tech start-up.",
          "Flexible work hours (4 hours per day).",
          "A collaborative and supportive work environment.",
          "Professional development and growth opportunities."
        ]
      },
];
  