/** @format */
import React, { useEffect, useState } from "react";

import { checkProfileExist } from "./../extension/setProfileIcon";
import { userAuthenticated } from "./../extension/currentUser";

import {
  url_path,
  config,
} from "./../extension/exportedStrings";

import { app_icon2, profile_icon, food_icon, app_icon, soci_coin } from "./../extension/exportedIcons";
import "./../style/account-settings.css";

const AccountSetting = ({fullname, email, dob}) => {

  const [profilePic, setProfilePic] = useState(profile_icon);
 
  useEffect(() => {
    async function fetchProfileImage() {
      const imageSource = await checkProfileExist(userAuthenticated);
      setProfilePic(imageSource);
    }

    fetchProfileImage();
  }, []);
  
  return (
    <div>
      <div className="accountSettingsDiv">
        <img
          id="accProfileIcon"
          className="accProfileIcon"
          src={profilePic}
          alt="Profile Icon"
        />
        <img
          id="accCountryIcon"
          class="accCountryIcon"
          src={food_icon}
        />
      </div>
      <div className="accountInputDiv">
        <h1 class="accountSettingsLabel">Username</h1>
      </div>
      <input class="accountSettingsInput" value={userAuthenticated}/>
      <div className="accountInputDiv">
        <h1 class="accountSettingsLabel">Fullname</h1>
      </div>
      <input class="accountSettingsInput" value={fullname} />
      <div className="accountInputDiv">
        <h1 class="accountSettingsLabel">Email</h1>
      </div>
      <input class="accountSettingsInput" value={email} />
      <div className="accountInputDiv">
        <h1 class="accountSettingsLabel">Birthday</h1>
      </div>
      <input class="accountSettingsInput" value={dob}/>
    </div>
  );
};

export default AccountSetting;
