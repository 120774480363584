/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import UpdateSale from "./../design/UpdateSale";

import MinibarComponent from "./../design/Minibar.js";
import FooterComponent from "./../design/Footer.js";

import { userAuthenticated } from "./../extension/currentUser";

import { app_icon } from "./../extension/exportedIcons";
import { url_path, retrieveOrder, retrieveItem, copyrightLabel } from "./../extension/exportedStrings";

import "./../style/order-manage.css";

function OrderManage() {
  const navigate = useNavigate();
  const [selectedChoice, setSelectedChoice] = useState("buyer");
  const [isUpdate, setUpdate] = useState(false);

  const [orderArray, setOrderArray] = useState([]);
  const [salesArray, setSalesArray] = useState([]);
  
  const [orderID, setOrderID] = useState("");
  const [trackingLabel, setTrackingLabel] = useState("");
  const [orderLabel, setOrderLabel] = useState(""); 

  useEffect(() => {
    const configOrder = () => {
        const url = url_path + retrieveOrder;
        const data = {
          username: userAuthenticated, // Replace with the actual username
        };
    
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
          })
          .then((data) => {
            if (JSON.stringify(data) == "[]") {
              setOrderArray(data);
            }
          })
          .catch((error) => {

          });
    }

    const configSales = () => {
      const url = url_path + retrieveItem;
      const data = {
        username: userAuthenticated, // Replace with the actual username
      };
  
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          if(JSON.stringify(data) == "[]") {
            setSalesArray(data);
          }
        })
        .catch((error) => {

        });
  }

    configOrder();
    configSales();
  }, []);

  const handleChoiceClick = (choice) => {
    setSelectedChoice(choice);
  };

  const openUpdate = (orderIDValue, orderValue, trackingValue) => {
    if (!isUpdate) {
      setOrderID(orderIDValue)
      setOrderLabel(orderValue)
      setTrackingLabel(trackingValue)
      setUpdate(true);
    }
  };
  
  return (
    <div>
      <MinibarComponent />
      <div className="order-manageDiv">
        <div className="orderTableDiv">
          {selectedChoice === "buyer" ? (
            <table className="orderTable">
              <tbody>
                {orderArray.length > 0 ? (
                  orderArray.map((orders, index) => (
                    <tr className="orderRow" key={index}>
                      <div className="orderHeader">
                        <div className="orderHeaderDiv">
                          <div className="orderHeaderDivLeft">
                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Order no.</p>
                              <p className="orderLabel2">ORDER # {orders.id}</p>
                            </div>
                          </div>

                          <div className="orderHeaderDivRight">
                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Order placed</p>
                              <p className="orderLabel2">{orders.date}</p>
                            </div>

                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Price</p>
                              <p className="orderLabel2">{orders.price}</p>
                            </div>

                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Deliver to</p>
                              <p className="orderLabelHighlight">
                                {orders.purchase}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="orderBody">
                        <div className="orderBodyDivLeft">
                          <img
                            id="orderIcon"
                            className="orderIcon"
                            src={orders.image}
                            onClick={() => {
                              if (orders.name.includes("Shawl")) {
                                // Handle click for specific item type
                              } else {
                                // Handle click for other item types
                              }
                            }}
                          />
                          <div className="orderTextDiv">
                            <p className="orderUsername">{orders.name}</p>
                            <p className="orderArrival">{orders.delivery}</p>
                          </div>
                        </div>

                        <div className="orderBodyDivRight">
                          <button
                            className="orderUpdate"
                            onClick={() =>
                              window.open(orders.tracking, "_blank")
                            }
                          >
                            Track package
                          </button>
                          <button
                            className="orderUpdate"
                            onClick={() => {
                              const orderId = "12345"; // Replace with the actual order ID
                              const mailtoLink = `mailto:p.elegant14@gmail.com?subject=Refund Request&body=Hello, I would like to request a refund for the following item: ${orders.id}`;

                              window.open(mailtoLink, "_blank");
                            }}
                          >
                            Refund Item
                          </button>
                        </div>
                      </div>
                    </tr>
                  ))
                ) : (
                  <div className="choiceErrorDiv">
                    <p className="choiceErrorLabel">No current purchases</p>
                  </div>
                )}
              </tbody>
            </table>
          ) : (
            <table className="orderTable">
              <tbody>
                {salesArray.length > 0 ? (
                  salesArray.map((orders, index) => (
                    <tr className="orderRow" key={index}>
                      <div className="orderHeader">
                        <div className="orderHeaderDiv">
                          <div className="orderHeaderDivLeft">
                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Sales no.</p>
                              <p className="orderLabel2">ORDER # {orders.id}</p>
                            </div>
                          </div>

                          <div className="orderHeaderDivRight">
                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Sale placed</p>
                              <p className="orderLabel2">{orders.date}</p>
                            </div>

                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Price</p>
                              <p className="orderLabel2">{orders.price}</p>
                            </div>

                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Deliver to</p>
                              <p className="orderLabelHighlight">
                                {orders.purchase}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="orderBody">
                        <div className="orderBodyDivLeft">
                          <img
                            id="orderIcon"
                            className="orderIcon"
                            src={orders.image}
                            onClick={() => {
                              if (orders.name.includes("Shawl")) {
                                // Handle click for specific item type
                              } else {
                                // Handle click for other item types
                              }
                            }}
                          />
                          <div className="orderTextDiv">
                            <p className="orderUsername">{orders.name}</p>
                            <p className="orderArrival">{orders.delivery}</p>
                          </div>
                        </div>

                        <div className="orderBodyDivRight">
                          <button
                            className="orderUpdate"
                            onClick={() =>
                              openUpdate("", "")
                            }
                          >
                            Update Sale
                          </button>
                          <button
                            className="orderUpdate"
                            onClick={() => {
                              const orderId = "12345"; // Replace with the actual order ID
                              const mailtoLink = `mailto:p.elegant14@gmail.com?subject=Refund Request&body=Hello, I would like to request a refund for the following item: ${orders.id}`;

                              window.open(mailtoLink, "_blank");
                            }}
                          >
                            Confirm Sale
                          </button>
                        </div>
                      </div>
                    </tr>
                  ))
                ) : (
                  <div className="choiceErrorDiv">
                    <p className="choiceErrorLabel">No current sales</p>
                  </div>
                )}
              </tbody>
            </table>
          )}
        </div>

        <div className="orderChoiceDiv">
          <div
            className={`buyerChoiceDiv ${
              selectedChoice === "buyer" ? "choiceSelected" : ""
            }`}
            onClick={() => handleChoiceClick("buyer")}
          >
            <p className="choiceLabel">Orders</p>
          </div>

          <div
            className={`sellerChoiceDiv ${
              selectedChoice === "seller" ? "choiceSelected" : ""
            }`}
            onClick={() => handleChoiceClick("seller")}
          >
            <p className="choiceLabel">Sales</p>
          </div>
        </div>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>

      <UpdateSale
        isOpen={isUpdate}
        setIsOpen={setUpdate}
        orderID={orderID}
        orderLabel={orderLabel}
        trackingLabel={trackingLabel}
        setOrderLabel={setOrderLabel}
        setTrackingLabel={setTrackingLabel}
      />
    </div>
  );
}

export default OrderManage;
