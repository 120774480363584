export const createAcc = async (url, username, fullname, email, password, dob) => {
    const data = {
      username: username, // Replace with the actual username
      fullname: fullname, 
      email: email, 
      password: password, 
      dob: dob, 
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };