/** @format */
import React, { useEffect, useState } from "react";

import { changePassword } from "./../data/changePassword";
import { checkProfileExist } from "./../extension/setProfileIcon";
import { userAuthenticated } from "./../extension/currentUser";

import { validateEmail } from "./../extension/inputValidity";
import { url_path, config, paymentDetailsPath } from "./../extension/exportedStrings";

import {
  app_icon2,
  profile_icon,
  food_icon,
  app_icon,
  soci_coin,
  paypal_icon,
} from "./../extension/exportedIcons";
import "./../style/paypal-payment.css";

const PaypalPayment = ({ setSelected, setEmail }) => {
  const [result, setResult] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle the Enter key press here
      setPaymentDetails();
    }
  };

  const setPaymentDetails = () => {
    const email = document.getElementById("payment2");
    const fullname = document.getElementById("payment1");

    if(fullname.value.trim() == ""){
      fullname.style.borderColor = "red";

      return;
    }else if(email.value.trim() == "email" || !validateEmail(email.value.trim())){
      email.style.borderColor = "red";

      return;
    }
    const url = url_path + paymentDetailsPath;

    const data = {
      username: userAuthenticated,
      type: "paypal",
      email: email.value.trim(), 
      fullname: fullname.value.trim(),
      token: "",
    };

    fetch(url, {
      method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }).then(async (result) => {
        console.log(result)
    });
    
    setEmail(email.value.trim())
    setSelected("");
  }

  return (
    <div>
      <div className="paymentDetailsDiv">
        <img
          src={paypal_icon}
          className="paymentDetailsIcon"
        />
        <p className="paymentDetailsLabel">Add a PayPal Account</p>

        <input
          id="payment1"
          className="divPaypalInput"
          placeholder="Enter your PayPal name"
          onKeyPress={handleKeyPress}
          onBlur={(e) => (e.target.style.borderColor = "#e3e3e3")}
        />

        <input
          id="payment2"
          className="divPaypalInput"
          placeholder="Enter your PayPal email"
          onKeyPress={handleKeyPress}
          onBlur={(e) => (e.target.style.borderColor = "#e3e3e3")}
        />

        <button class="paypalDetailsButton" onClick={setPaymentDetails}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default PaypalPayment;
