// process.env.REACT_APP_HEROKU_URL
// export const url_path = process.env.HEROKU_URL || "http://localhost:5000/";
export const url_path = "";
export const googleClientID = process.env.REACT_APP_CLIENTID || "827120467315-p9spgrtl1kkmi1bqgit8eiufpn7lk25r.apps.googleusercontent.com";

export const login = "https://login-rpu55a24ja-uc.a.run.app" || "login";
export const register = "https://register-rpu55a24ja-uc.a.run.app" || "register";
export const check = "https://detailcheck-rpu55a24ja-uc.a.run.app" || "detailCheck";
export const config = "https://getuser-rpu55a24ja-uc.a.run.app" || "user";
export const configSearch = "https://getusers-rpu55a24ja-uc.a.run.app" || "users";
export const emailPath = "https://application-mail-rpu55a24ja-uc.a.run.app" || "application-mail";
export const resetPath = "https://change-password-rpu55a24ja-uc.a.run.app" || "change-password";
export const deletePath = "https://delete-rpu55a24ja-uc.a.run.app" || "delete";
export const paymentIntentPath = "https://create-payment-intent-rpu55a24ja-uc.a.run.app" || "create-payment-intent";
export const savePath = "https://store-payment-intent-rpu55a24ja-uc.a.run.app" || "store-payment-intent";
export const paymentDetailsPath = "https://payment-details-rpu55a24ja-uc.a.run.app" || "payment-details";

// coin
export const getCoins = "https://socicoin-rpu55a24ja-uc.a.run.app" || "socicoin";

// ad
export const getWatchAD = "https://getwatchad-rpu55a24ja-uc.a.run.app" || "getWatchAD";
export const retrieveWatchAD = "https://retrievewatchad-rpu55a24ja-uc.a.run.app" || "retrieveWatchAD";

// invite
export const inviteLink = "https://invite-link-rpu55a24ja-uc.a.run.app" || "invite-link";
export const confirmInvite = "https://confirm-invite-rpu55a24ja-uc.a.run.app" || "confirm-invite";

// transaction
export const createItem = "https://createitem-rpu55a24ja-uc.a.run.app" || "create-item"
export const retrieveItem = "https://retrieveitem-rpu55a24ja-uc.a.run.app"
export const updateItem = "https://updateitem-rpu55a24ja-uc.a.run.app"
export const retrieveOneItem = "https://retrieveoneitem-rpu55a24ja-uc.a.run.app"
export const deleteItem = "https://deleteitem-rpu55a24ja-uc.a.run.app"

export const createOrder = "https://createorder-rpu55a24ja-uc.a.run.app"
export const retrieveOrder = "https://retrieveorder-rpu55a24ja-uc.a.run.app"
export const updateOrder = "https://updateorder-rpu55a24ja-uc.a.run.app"
export const deleteOrder = "https://deleteorder-rpu55a24ja-uc.a.run.app"
export const adminOrder = "https://adminorder-rpu55a24ja-uc.a.run.app"

export const stripePublishableKey = process.env.STRIPE_PUBLISHABLE_KEY || "pk_live_51M5o3IGWslu84LeouTM9BYyN67OgYHCPPsFswIAXet4LAs1TedaeNgoubBwfJQAt6TGz3jMRSFtHbKFmB73nBjoo00tQbCIP6N";
export const stripeClientKey = "";

export const url_link = "https://socirankfiles.xyz/projectweb/mobileconnect/";

export const websiteLink = "https://socirank.com/invite/"
// export const websiteLink = "http://localhost:3000/invite/"
export const mainLink = "https://socirank.com/";
export const websiteName = "SociRank";
export const googleLoginSecretKey = "googleLoginSecret123321"

export const copyrightLabel = "SociRank © 2023"

