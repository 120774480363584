import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { checkProfileExist } from "./../extension/setProfileIcon";
import { app_icon2, profile_icon } from "./../extension/exportedIcons";
import { userAuthenticated } from "./../extension/currentUser";
import { url_path, configSearch } from "./../extension/exportedStrings";
import SearchText from "./Search";
import Settings from "./Settings.js";

import "./../style/minibar.css";

const Minibar = () => {
  const navigate = useNavigate();

  const [isSettings, setSettings] = useState(false);
  const [profilePic, setProfilePic] = useState(profile_icon);
  const [isSearch, setSearch] = useState(false);
  const [listArray, setListArray] = useState([]);
  const [filteredArray, setFilterArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const searchRef = useRef(null);
  const profileDivRef = useRef(null);

  useEffect(() => {
    async function fetchProfileImage() {
      const imageSource = await checkProfileExist(userAuthenticated);
      setProfilePic(imageSource);
    }

    fetchProfileImage();
    searchUsers();
  }, [userAuthenticated]);


  const goHome = () => {
    let path = `/`;
    navigate(path);
  };

  const goCoin = () => {
    let path = `/coin`;
    navigate(path);
  };

  const goNotification = () => {
    let path = `/notification`;
    navigate(path);
  };

  const goCheckout = () => {
    let path = `/checkout`;
    navigate(path);
  };

  const goSetting = () => {
    const item = document.getElementById("profile-img");
    if (isSettings) {
      setSettings(false);
      item.style.borderColor = "#e3e3e3";
    } else {
      setSettings(true);
      item.style.borderColor = "#5d3fd3";
    }
  };

  const openSearch = () => {
    if (!isSearch) {
      setSearch(true);
    }
  };

  const searchUsers = () => {
    const url = url_path + configSearch;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const limitedData = data.slice(0, 15);
        setListArray(data);
        setFilterArray(limitedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const updateSearch = (value) => {
    const filteredArray = listArray.filter((item) =>
      item.username.toLowerCase().includes(value.toLowerCase().trim())
    );
    const limitedData = filteredArray.slice(0, 15);
    setFilterArray(limitedData);
  };

  const handleKeyDown = () => {
    // Clear the timeout if there's any
    if (typingTimeout) {
      setLoading(true);
      clearTimeout(typingTimeout);
    }
  };
  
  const handleKeyUp = (e) => {
    // Set a timeout to trigger the search after typing has stopped
    const searchText = e.target.value;
    clearTimeout(typingTimeout);
    setTypingTimeout(setTimeout(() => {
      updateSearch(searchText.toLowerCase());
      setLoading(false);
    }, 200)); // Adjust the delay as needed (e.g., 1000 milliseconds = 1 second)
  };

  return (
    <div>
      <div className="minibarDiv">
        <div className="mini-bar">
          <img
            src={app_icon2}
            className="mini-img"
            width="30"
            height="30"
            onClick={goHome}
          />
          <p className="title" id="title" onClick={goHome}>
            <Link className="plain-link" to={"/"}>
              SociRank - Under Construction
            </Link>
          </p>
          <div ref={searchRef} onClick={openSearch}>
            <input
              className="search-bar"
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                const searchText = e.target.value;
                setSearchText(searchText);
              }}
              onKeyDown={(e) => {
                const currentKey = e.key;
                if (!['Alt', 'Shift', 'Control', 'Meta'].includes(currentKey)) {
                  handleKeyDown();
                }
              }}
              onKeyUp={handleKeyUp}
            />
            <svg className="search-icon" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            <SearchText
              isOpen={isSearch}
              setIsOpen={setSearch}
              searchRef={searchRef}
              listArray={filteredArray}
              isLoading={isLoading}
              setLoading={setLoading}
            />
          </div>
          <div className="iconDiv">
            <div className="icon-textDiv" onClick={goCoin}>
              <svg width="30" height="30" class="icon-img" viewBox="0 0 16 16">
                <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> 
                <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
              </svg>
              <p className="icon-text">Get Coins</p>
            </div>
            <div className="icon-textDiv" onClick={goNotification}>
              <svg width="30" height="30" class="icon-img" viewBox="0 0 16 16">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/> 
              </svg>
              <p className="icon-text">Notification</p>
            </div>
            <div className="icon-textDiv2" onClick={goCheckout}>
              <svg width="30" height="30" class="icon-img" viewBox="0 0 16 16">
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
              <p className="icon-text">Checkout</p>
            </div>
            <img
              src={profilePic}
              className="profile-img"
              id="profile-img"
              width="30"
              height="30"
              ref={profileDivRef}
              onClick={goSetting}
            />
          </div>
        </div>

        <hr className="lineView2" />
        <Settings
          image={profilePic}
          isOpen={isSettings}
          setIsOpen={setSettings}
          profileDivRef={profileDivRef}
        />
      </div>
    </div>
  );
};

export default Minibar;
