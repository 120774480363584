/** @format */
import React, { useEffect, useState } from "react";

import { changePassword } from "./../data/changePassword";
import { checkProfileExist } from "./../extension/setProfileIcon";
import { userAuthenticated } from "./../extension/currentUser";

import { url_path, config } from "./../extension/exportedStrings";

import {
  app_icon2,
  profile_icon,
  food_icon,
  app_icon,
  soci_coin,
} from "./../extension/exportedIcons";
import "./../style/security-settings.css";

const SecuritySetting = ({ email }) => {

  const [result, setResult] = useState("");
  
  const changePass = () => {
    const password1 = document.getElementById("password1");
    const password2 = document.getElementById("password2");

    if (password1.value.trim() == "") {
      password1.style.borderColor = "red";
      return;
    } else if (password2.value.trim() == "" || password2.value.length < 8) {
      password2.style.borderColor = "red";
      setResult("Password too short")
      return;
    }

    changePassword(email, password1.value.trim(), password2.value.trim());
    
    password1.value = "";
    password2.value = "";

    setResult("Password successfully changed")

  };


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle the Enter key press here
      changePass();
    }
  };

  return (
    <div>
      <div className="securitySettingDiv">
        <p className="securitySettingLabel">Change password</p>

        <input
          id="password1"
          className="divInput"
          type="password"
          placeholder="Current password"
          onKeyPress={handleKeyPress}
          onBlur={(e) => e.target.style.borderColor = "#e3e3e3"}
        />

        <input
          id="password2"
          className="divInput"
          type="password"
          placeholder="New password"
          onKeyPress={handleKeyPress}
          onBlur={(e) => e.target.style.borderColor = "#e3e3e3"}
        />

        <button class="divButton" onClick={changePass}>
          Change password
        </button>

        <p className="securityErrorLabel">
            {result}
          </p>
      </div>
    </div>
  );
};

export default SecuritySetting;
