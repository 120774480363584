/** @format */
import React, { useEffect, useState } from "react";

import { changePassword } from "./../data/changePassword";
import { checkProfileExist } from "./../extension/setProfileIcon";
import { userAuthenticated } from "./../extension/currentUser";

import { url_path, stripePublishableKey } from "./../extension/exportedStrings";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import PaypalPayment from "./../payment/PaypalPayment.js";
import StripePayment from "./../payment/StripePayment.js";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  app_icon2,
  profile_icon,
  food_icon,
  app_icon,
  soci_coin,
  credit_card,
  paypal_icon,
} from "./../extension/exportedIcons";
import "./../style/payment-settings.css";


const PaymentSetting = ({clientSecret, email, setEmail}) => {

  const stripePromise = loadStripe(stripePublishableKey);

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const paymentType = (selected) => {
    if (selected === "Card") {
      return (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <StripePayment setSelected={setSelectedOption} clientSecret={clientSecret} />
        </Elements>
      );
    } else if (selected === "Paypal") {
      return <PaypalPayment setSelected={setSelectedOption} setEmail={setEmail}/>;
    }
    // Add more conditions for additional skills if needed
    return null;
  };
  
  return (
    <div>
      {selectedOption === "" ? (
        <div className="paymentSettingsDiv">
          <h1 id="paymentSettingLabel" className="paymentSettingLabel">
            Safely Configure Withdrawal Details to Receive Real-World Currency
          </h1>

          <button
            id="gSignInWrapper"
            className="paymentChoiceButton"
            value="submit"
            onClick={() => handleOptionClick("Card")}
          >
            <img
              src={credit_card}
              className="paymentChoiceIcon"
              alt="Google Icon"
            />
            Add Debit/Credit Card
          </button>
          <button
            id="gSignInWrapper"
            className="paymentChoiceButton"
            value="submit"
            style={{ backgroundColor: "#0096FF" }}
            onClick={() => handleOptionClick("Paypal")}
          >
            <img
              src={paypal_icon}
              className="paymentChoiceIcon"
              alt="Google Icon"
            />
            {email}
          </button>

          <p className="paymentErrorLabel">
            For security reasons, we do not collect or store payment account passwords.
          </p>
        </div>
      ) : (
        // Render something else if 'selected' is not empty
        <div className="paymentSettingsDiv">{paymentType(selectedOption)}</div>
      )}

      </div>
  );
};

export default PaymentSetting;
