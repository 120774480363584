/** @format */
import React, { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import { getCoinValue } from "./../data/coinData";

import { app_icon, soci_coin } from "./../extension/exportedIcons";
import { cashName, cashPrice, cashAmount } from "./../data/cashArray";

import { copyrightLabel } from "./../extension/exportedStrings";

import "./../style/cashout.css";
const Cashout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [index, setIndex] = useState(null);
  const [cashArray, setCashArray] = useState([]);
  const [coinValue, setCoinValue] = useState("0");
  const [errorCoin, setErrorCoin] = useState("");
  useEffect(() => {
    document.title = "SociRank - Exchange"; // Change the title here
    const currentPath = location.pathname;
    const part = currentPath.split("/");
  
    setIndex(part[2]);
    setCashArray(cashName)

    getCoinValue.then(result => {
      setCoinValue(result)
    });
  }, []);
  const goExchange = (index) => {
    let path = `/exchange/${index}`;
    navigate(path);

    setErrorCoin("")
    setIndex(index);
  };
  
  const redeemCoin = () => {
    if (coinValue >= cashPrice[index]) {
      console.log("works")
    }else{
      setErrorCoin("You don't have enough SociCoins")
    }
  };
  return (
    <div>
      <MinibarComponent />
      <div className="exchangeBox">
        <div className="leftExchangeDiv">
          <img
            src={cashName[index]}
            className="exchangeImg"
            alt="Image Description"
          />
        </div>

        <div className="rightExchangeDiv">
          <p className="exchangeLabel">
            Redeem digital currency for real money
          </p>
          <p className="exchangeLabel2">
            When you provide your payment details, such as your PayPal or Wise
            email, and redeem your coins, rest assured that we value your
            security. Your payment details are securely stored, and we do not
            require your password to ensure your financial safety. This practice
            is designed to protect you from potential scams and build trust
            within our community.
          </p>
          <div className="exchangeCoinDiv">
            <img
              src={soci_coin}
              className="exchangeCoinImg"
              width="30"
              height="30"
            />
            <p className="exchangeCoinLabel">{coinValue}/{cashPrice[index]}</p>
          </div>
          <button className="exchangeButton" onClick={redeemCoin}>Redeem {cashAmount[index]}</button>
          <p className="errorCoinLabel">{errorCoin}</p>
        </div>
      </div>
      <div className="exchange-container" id="cashContainer">
        {cashArray.map((array, int) => (
          <div className="exchangeTable" onClick={() => goExchange(int)}>
            <p className="cashLabel">{cashAmount[int]}</p>
            <img src={array} className="cashImg" alt="Image Description" />
            <div className="cashView"></div>

            <div className="priceDiv">
              <img
                src={soci_coin}
                className="coinImg2"
                width="30"
                height="30"
              />
              <p className="cashLabel2">{cashPrice[int]}</p>
            </div>
          </div>
        ))}
      </div>
        <div className="FooterDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={app_icon}
              alt="Image"
            />
            <FooterComponent />
           </div>

          <p className="copyrightLabel2">
            {copyrightLabel}
          </p>
        </div>
    </div>
  );
};

export default Cashout;
