/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import { userAuthenticated } from "./../extension/currentUser";

import LoginDialog from "./../dialog/LoginDialog";
import RegisterDialog from "./../dialog/RegisterDialog";

import "./../style/application.css";
import FooterComponent from "./Footer.js";

const Application = () => {
  useEffect(() => {
    document.title = "SociRank - Application"; // Change the title here

    return () => {
      // This return function is called when the component is unmounted.
      // You can perform any cleanup or checks here.
      document.body.style.overflowY = "auto";
    };
  }, []);
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(false);
  const [isRegister, setIsRegister] = useState(false);

  const [applicationValue, setApplicationValue] = useState("");

  const goApply = (value) => {
    setApplicationValue(value);
    if (userAuthenticated) {
      let path = `/confirm-application/${value}`;
      navigate(path);
    } else {
      openDialog();
    }
  };

  const openDialog = () => {
    setIsLogin(true);
    document.body.style.overflowY = "hidden";
  };
  return (
    <div>
      <div className="applicationDiv">
        <div className="applicationheader">
          <img src={app_icon2} className="Image" width="25" height="25" />
          <p className="applicationHeaderLabel">SociRank</p>
        </div>

        <div className="applicationBoxDiv">
          <p className="applicationLabel">Current positions available</p>
          <button
            className="applicationButton"
            onClick={(e) =>
              goApply(e.target.innerText.substring("Apply: ".length))
            }
          >
            Apply: Social Media Manager
          </button>

          <button
            className="applicationButton"
            onClick={(e) =>
              goApply(e.target.innerText.substring("Apply: ".length))
            }
          >
            Apply: Growth Marketer
          </button>
        </div>
      </div>

      <div className="footerDiv2">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>

      <LoginDialog
        isOpen={isLogin}
        setIsOpen={setIsLogin}
        setIsExtra={setIsRegister}
        value={applicationValue}
      />
      <RegisterDialog
        isOpen={isRegister}
        setIsOpen={setIsRegister}
        setIsExtra={setIsLogin}
        value={applicationValue}
      />
    </div>
  );
};

export default Application;
