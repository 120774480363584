/** @format */
import React from "react";

import {
  mainLink
} from "./../extension/exportedStrings";
const FooterComponent = () => {
  const message = `Check out this website: ${mainLink}`;
  const encodedMessage = encodeURIComponent(message);
  const whatsappSharingLink = `whatsapp://send?text=${encodedMessage}`;

  return (
    <div class="container">
      <div class="row">
        <div class="footer-col">
          <h4>company</h4>
          <ul>
            <li>
              <a href="/about-us" target="_blank">About Us</a>
            </li>
            <li>
              <a href="/terms-condition" target="_blank">Privacy Policy</a>
            </li>
            <li>
              <a href="/terms-condition" target="_blank">Refund Policy</a>
            </li>
            <li>
              <a href="/terms-condition" target="_blank">Services Policy</a>
            </li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Explore</h4>
          <ul>
            <li>
              <a href="/">Shop</a>
            </li>
            <li>
              <a href="/">SociCoin</a>
            </li>
            <li>
              <a href="/">Ranking</a>
            </li>
            <li>
              <a href={whatsappSharingLink}>Invite friends</a>
            </li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Contact</h4>
          <ul>
            <li>
              <a href="/application" target="_blank">Job Application</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/socirank" target="_blank">LinkedIn</a>
            </li>
            <li>
              <a href="mailto:socirank@gmail.com" target="_blank">Contact us</a>
            </li>
            <li>
              <a
                href="https://wa.link/vs81kn"
                target="_blank"
              >
                WhatsApp
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Socials</h4>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/socirank/"
                target="_blank"
              >
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/socirank/"
                target="_blank">Instagram</a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@socirank"
              target="_blank">TikTok</a>
            </li>
            <li>
              <a href="https://www.youtube.com/@SociRank"
              target="_blank">Youtube</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;


