/** @format */
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { userAuthenticated } from "./../extension/currentUser";

import MinibarComponent from "./../design/Minibar.js";
import FooterComponent from "./../design/Footer.js";

import { app_icon } from "./../extension/exportedIcons";
import { url_path, copyrightLabel } from "./../extension/exportedStrings";
import "./../style/digital_good.css";

const DigitalGood = ({ isOpen, setIsOpen }) => {

  const [selectedOption, setSelectedOption] = useState("1");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const renderSettings = (selected) => {
    if (selected === "1") {
      // return ;
    } else if (selected === "2") {
      // return ;
    } 
    else if (selected === "3") {
      // return ;
    }else if (selected === "4") {
      // return ;
    }
    // Add more conditions for additional skills if needed
    return null;
  };


  return (
    <div>
      <MinibarComponent />
      <div class="digitalLayout">
        <div className="digitalLeftDiv">
          <div
            className={`digitalTitleDiv ${
              selectedOption === "1" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("1")}
          >
            <p className="digitalTitleLabel">Popular Today</p>
            <svg class="digitalTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className={`digitalTitleDiv ${
              selectedOption === "2" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("2")}
          >
            <p className="digitalTitleLabel">Daily Auctions</p>
            <svg class="digitalTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className={`digitalTitleDiv ${
              selectedOption === "3" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("3")}
          >
            <p className="digitalTitleLabel">Your Personal Bids</p>
            <svg class="digitalTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className={`digitalTitleDiv ${
              selectedOption === "4" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("4")}
          >
            <p className="digitalTitleLabel">Upload and Verify</p>
            <svg class="digitalTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
        </div>
        <div className="digitalRightDiv">
          {renderSettings(selectedOption)}
        </div>
      </div>

      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>
        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default DigitalGood;
