/** @format */
import React, { useEffect, useState } from "react";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import "./../style/TermsOfService.css";
import FooterComponent from "./Footer.js";

const AboutUs = () => {
  useEffect(() => {
    document.title = "SociRank - About Us"; // Change the title here
  }, []);
  return (
    <div>
      <div className="tosBackground">
        <div className="tosHeader">
          <img src={app_icon2} className="Image" width="25" height="25" />
          <p className="tosHeaderLabel">About Us</p>
        </div>

        <div className="tosDiv">
          <p className="tosDivLabel">Join Us</p>
          <p className="tosDivBody">
            Get paid by promoting your talent. You may earn SociCoins depending on the popularity of your posts by publishing material on the app and seeing user reactions to it. You can then exchange those SociCoins for real money.
          </p>
          <p className="tosDivLabel">SociCoins</p>
          <p className="tosDivBody">
          Users may gain SociCoins in a number of different ways, such as by gaining likes on their posts, watching ad videos, or placing well on the daily leaderboard. The user may then buy in-app features with SociCoins or swap SociCoins for cash.
          </p>
          <p className="tosDivLabel">Daily Leaderboard</p>
          <p className="tosDivBody">
          To rank highly on the daily leaderboard, post the most entertaining and eye-catching material. Winners will earn a generous prize in the form of SociCoins.
          </p>
          <p className="tosDivLabel">Messaging</p>
          <p className="tosDivBody">
          Message and communicate with your family and friends, with more cool features to come in this area.
          </p>
          <p className="tosDivLabel">Top Buddies</p>
          <p className="tosDivBody">
          By messaging your friends, you can earn buddy points. You can also select the type of relationship you have with a person, such as best friend, close friends, family, and more.
          </p>
        </div>
      </div>
      <div className="tosFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel2">
            {copyrightLabel}
          </p>
      </div>
    </div>
  );
};

export default AboutUs;
