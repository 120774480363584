/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  app_icon,
  app_icon2,
  facebook_icon,
  instagram_icon,
  linkedin_icon,
} from "./../extension/exportedIcons";
import {
  url_path,
  login,
  copyrightLabel,
} from "./../extension/exportedStrings";

import "./../style/application-confirmed.css";
import FooterComponent from "./Footer.js";

import CryptoJS from "crypto-js";
import { googleLoginSecretKey } from "./../extension/exportedStrings";
function ApplicationConfirmed() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "SociRank - Confirmed";
  }, []);

  const goHome = () => {
    let path = `/`;
    navigate(path);
  };

  return (
    <div>
      <div className="applicationheader2">
        <img src={app_icon2} className="Image" width="25" height="25" />
        <p className="applicationHeaderLabel">SociRank</p>
      </div>
      <div className="applicationConfDiv">
        <p className="applicationConfLabel">
          Congratulations! Your application has been received successfully.
          We're excited to start the review process and look forward to getting
          to know more about you. Thank you for choosing us, and best of luck
          with your application!
        </p>

        <button class="applicationButton2" onClick={goHome}>
          Explore page
        </button>

        <p className="applicationSocialLabel">
          Check out and connect with us through our socials!
        </p>

        <div className="appIconDiv">
          <a href="https://www.linkedin.com/company/socirank" target="_blank">
            <img className="icon" src={linkedin_icon} alt="LinkedIn" />
          </a>
          <a href="https://www.instagram.com/socirank/" target="_blank">
            <img className="icon" src={instagram_icon} alt="Image" />
          </a>
          <a href="https://www.facebook.com/socirank/" target="_blank">
            <img className="icon" src={facebook_icon} alt="Image" />
          </a>
        </div>
      </div>

      <div className="applicationFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
}

export default ApplicationConfirmed;
