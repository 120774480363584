/** @format */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";
import BioDialog from "./../dialog/BioDialog.js";

import { generateStars, recordText } from "./../extension/generateStars";

import { app_icon2, profile_icon, food_icon, app_icon, soci_coin } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import { checkProfileExist } from "./../extension/setProfileIcon";
import { userAuthenticated } from "./../extension/currentUser";

import {
  url_path,
  config,
} from "./../extension/exportedStrings";

import { cashName, cashPrice, cashAmount } from "./../data/cashArray";
import "./../style/profile.css";

const Profile = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname.slice(1));

  const [profilePic, setProfilePic] = useState(profile_icon);
  const [firstButton, setFirstButton] = useState("Edit Profile");
  const [secondButton, setSecondButton] = useState("Add Item");

  const [bioText, setBioText] = useState("");
  const [isBioOpen, setBioOpen] = useState(false);
 
  useEffect(() => {
    document.title = `${currentPath} - SociRank`;
    const bioInput = document.getElementById("profileBio")
    
    async function fetchProfileImage() {
      const imageSource = await checkProfileExist(currentPath);
      setProfilePic(imageSource);
    }

    fetchProfileImage();

    const configUser = () => {
        if(currentPath != userAuthenticated){
          // check follow status also
          setFirstButton("Follow")
          setSecondButton("Message")
        }

        const url = url_path + config;
        const data = {
          username: currentPath, // Replace with the actual username
        };
    
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
          })
          .then((data) => {
            const responseData = JSON.parse(data);
            const bioData = responseData.bio
            if(!bioData.trim() == ""){
              bioInput.style.border = "1px solid #e3e3e3"
              setBioText(bioData);
            }else{
              bioInput.style.border = "none"
              bioInput.style.pointerEvents = "none";
            }
          })
          .catch((error) => {
              setCurrentPath("User not found"); 
          });
    }

    configUser();
  }, []);

  const openBio = () => {
    setBioOpen(true);
    document.body.style.overflowY = "hidden";
  };

  return (
    <div>
      <MinibarComponent />

      <div className="ProfileDiv">
        <img src={profilePic} className="profilePic" id="profilePic" />

        <div className="profileTextDiv">
          <p id="profileUsername" class="profileUsername">
                {currentPath}
          </p>
          <p id="profileStatus" class="profileStatus">
            {recordText[5]}
          </p>
          <p id="profileStar" class="profileStar">
            {generateStars(5)}
          </p>
          <p id="profileBio" class="profileBio" onClick={openBio}>
              {bioText}
          </p>
        </div>
        <img src={food_icon} className="foodPic" id="foodPic" />
      </div>
      <div className="editDiv">
      <button className="profileButton">{firstButton}</button>
        <button className="profileButton">{secondButton}</button>
      </div>

      <div className="profileTextDiv2">
          <p id="profileStatusText" class="profileStatusText">
          User has no items
          </p>
      </div>

      <div className="item-container" id="itemContainer">
            
      </div>

      <div className="ProfileFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>

      <BioDialog text={bioText} isOpen={isBioOpen} setIsOpen={setBioOpen}/>
    </div>
  );
};

export default Profile;
