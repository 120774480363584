import { order_manage_icon, digital_manage_icon, socicoin_manage_icon, message_manage_icon, address_manage_icon, rating_manage_icon } from "./../extension/exportedIcons";

export const manageArray = [
    { name: "Orders", description: "Streamline your order management process with features for reordering, tracking, and refunds.", image: order_manage_icon, path: '/manage-order' },
    { name: "Digital Goods", description: "Effortlessly manage the sale, trade, and copyright protection of your digital goods.", image: digital_manage_icon, path: '/digital-good'},
    { name: "SociCoin", description: "Discover more about SociCoin and how you can earn it to unlock exclusive benefits and rewards.", image: socicoin_manage_icon, path: '/manage-order'},
    { name: "Messages", description: "Communicate effectively as either a buyer or seller, resolving any issues promptly through direct messaging.", image: message_manage_icon, path: '/manage-order' },
    { name: "Address", description: "Manage Your Delivery Address: Customize, Remove, or Set for Accurate Deliveries", image: address_manage_icon, path: '/manage-order' },
    { name: "Ratings & Reviews", description: "Manage Your Ratings & Reviews: Set, Review, Delete, or Update Feedback for Products and Services", image: rating_manage_icon, path: '/manage-order' }
];

export const salesArray = [
    { name: "Orders", description: "Streamline your order management process with features for reordering, tracking, and refunds.", image: order_manage_icon, path: '/manage-order' },
    { name: "Digital Goods", description: "Effortlessly manage the sale, trade, and copyright protection of your digital goods.", image: digital_manage_icon, path: '/manage-order'},
    { name: "SociCoin", description: "Discover more about SociCoin and how you can earn it to unlock exclusive benefits and rewards.", image: socicoin_manage_icon, path: '/manage-order'},
    { name: "Messages", description: "Communicate effectively as either a buyer or seller, resolving any issues promptly through direct messaging.", image: message_manage_icon, path: '/manage-order' },
    { name: "Address", description: "Manage Your Delivery Address: Customize, Remove, or Set for Accurate Deliveries", image: address_manage_icon, path: '/manage-order' },
    { name: "Ratings & Reviews", description: "Manage Your Ratings & Reviews: Set, Review, Delete, or Update Feedback for Products and Services", image: rating_manage_icon, path: '/manage-order' }
];
