/** @format */
import { url_path, resetPath } from "./../extension/exportedStrings";

export const changePassword = async (email, password1, password2) => {
  const url = url_path + resetPath;
  const data = {
    email: email,
    current_password: password1,
    new_password: password2,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      // Throw an error if the response status is not OK
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // If you need to work with the response data, you can do so here
    const responseData = await response.json();
    return responseData;

  } catch (error) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", error);
    throw error; // Re-throw the error to propagate it
  }
};
