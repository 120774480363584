/** @format */
import React, { useEffect, useState } from "react";

import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";
import CryptoJS from "crypto-js";

import { userAuthenticated } from "./../extension/currentUser";
import "./../style/settingsView.css";

import {
  app_icon,
  app_icon2,
  google_icon,
  apple_icon,
} from "./../extension/exportedIcons";

import {
  url_path,
  config,
  copyrightLabel,
  paymentPath,
  savePath,
  paymentDetailsPath
} from "./../extension/exportedStrings";

import AccountSetting from "./../settings/AccountSetting.js";
import PaymentSetting from "./../settings/PaymentSetting.js";
import SecuritySetting from "./../settings/SecuritySetting.js";
import DeleteSetting from "./../settings/DeleteSetting.js";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

const SettingsView = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Account"); // Initialize with the default selected option

  const [clientSecret, setClientSecret] = useState("");

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDOB] = useState("");

  const [paypalEmail, setPaypalEmail] = useState("Add Paypal")

  useEffect(() => {
    document.title = "SociRank - Settings"; // Change the title here

    const configUser = () => {
      const url = url_path + config;
      const data = {
        username: userAuthenticated, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          const responseData = JSON.parse(data);
          setFullname(responseData.fullname);
          setEmail(responseData.email);
          setDOB(responseData.dob);

          createPayment();
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    };

    const getPaymentDetails = () => {
      const url = url_path + paymentDetailsPath;
  
      const data = {
        username: userAuthenticated,
        type: "retrieve",
        email: "", 
        fullname: "",
        token: "",
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          const responseData = JSON.parse(data);
          setPaypalEmail(responseData[0].paypal_email)
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    }

    const createPayment = () => {
      const url = url_path + savePath;
  
      const data = {
        email: email, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
      }).then(async (result) => {
        var { clientToken } = await result.json();
        setClientSecret(clientToken);
        console.log("XAS",clientToken)
      });
    }

    configUser();
    getPaymentDetails();
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const goTerms = () => {
    const link = "/terms-condition";
    window.open(link, "_blank");
  };

  const goEmail = () => {
    const link = "mailto:socirank@gmail.com";
    window.open(link, "_blank");
  };

  const renderSettings = (selected) => {
    if (selected === "Account") {
      return <AccountSetting fullname={fullname} email={email} dob={dob}/>;
    } else if (selected === "Payment") {
      return <PaymentSetting clientSecret={clientSecret} email={paypalEmail} setEmail={setPaypalEmail}/>;
    } 
    else if (selected === "Security") {
      return <SecuritySetting email={email}/>;
    }else if (selected === "Delete") {
      return <DeleteSetting/>;
    }
    // Add more conditions for additional skills if needed
    return null;
  };

  return (
    <div>
      <MinibarComponent />

      <div class="settingsLayout">
        <div className="settingsLeftDiv">
          <div className="settingsLeftTitle">Account Management</div>
          <div
            className={`settingsTitleDiv ${
              selectedOption === "Account" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("Account")}
          >
            <p className="settingsTitleLabel">Account</p>
            <svg class="settingsTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className={`settingsTitleDiv ${
              selectedOption === "Payment" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("Payment")}
          >
            <p className="settingsTitleLabel">Payment</p>
            <svg class="settingsTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className={`settingsTitleDiv ${
              selectedOption === "Security" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("Security")}
          >
            <p className="settingsTitleLabel">Security</p>
            <svg class="settingsTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className={`settingsTitleDiv ${
              selectedOption === "Delete" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("Delete")}
          >
            <p className="settingsTitleLabel">Delete</p>
            <svg class="settingsTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div className="settingsLeftTitle2">Safety & Security</div>
          <div
            className="settingsTitleDiv"
            onClick={() => {
              goEmail();
              handleOptionClick("");
            }}
          >
            <p className="settingsTitleLabel">Contact us</p>
            <svg class="settingsTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className="settingsTitleDiv"
            onClick={() => {
              goTerms();
              handleOptionClick("");
            }}
          >
            <p className="settingsTitleLabel">Terms of services</p>
            <svg class="settingsTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className="settingsTitleDiv"
            onClick={() => {
              goTerms();
              handleOptionClick("");
            }}
          >
            <p className="settingsTitleLabel">Terms of use</p>
            <svg class="settingsTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
          <div
            className="settingsTitleDiv"
            onClick={() => {
              goTerms();
              handleOptionClick("");
            }}
          >
            <p className="settingsTitleLabel">Privacy policy</p>
            <svg class="settingsTitleIcon" viewBox="0 0 16 16">
              <path d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />{" "}
            </svg>
          </div>
        </div>
        <div className="settingsRightDiv">{renderSettings(selectedOption)}</div>
      </div>

      <div className="settingFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default SettingsView;
