import {
    url_path,
    getCoins,
} from "./../extension/exportedStrings";

let coinValue = null;

const retrieveCoin = async () => {
    if (coinValue !== null) {
        // If the value is already fetched, return it
        return coinValue;
    }

    const authenticationData = JSON.parse(localStorage.getItem('currentUser'));
    const username = authenticationData.username;

    const url = url_path + getCoins;
    const data = {
        username: username, // Replace with the actual username
    };

    try {
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
        }

        const responseData = await res.json();

        // Cache the value
        coinValue = responseData;

        return responseData;
    } catch (error) {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
        // Return an error value or handle the error as needed
        throw error;
    }
};

export const getCoinValue = retrieveCoin();
