import { url_path, check } from "./../extension/exportedStrings";

export const emailCheck = async (value) => {
    const url =  url_path + check; 
    const data = {
      username: value, 
      type: "email", 
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
  
      if (responseData === "success") {
        return 'success';
      } else {
        return responseData;
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error:", error);
      throw error; // Re-throw the error to propagate it
    }
};
  