/** @format */

import { url_path, emailPath } from './../extension/exportedStrings';

export const sendEmail = (email, title, fullname) => {
  const url = url_path + emailPath;
  const data = {
    email: email, // Replace with the actual username
    title: title,
    fullname: fullname,
  };

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json();
    })
    .then((data) => {
      // Handle the response data here
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Fetch error:", error);
    });
};
