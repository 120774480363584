/** @format */

import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import { url_path, updateOrder } from "./../extension/exportedStrings";
import "./../style/update-sale.css";

const UpdateSale = ({
  isOpen,
  setIsOpen,
  orderID,
  orderLabel,
  trackingLabel,
  setOrderLabel,
  setTrackingLabel,
}) => {
  const updateRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (updateRef.current && !updateRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen, updateRef]);

  const confirmData = () => {
    const url = url_path + updateOrder;
    const data = {
      id: orderID,
      purchase: orderLabel,  
      tracking: trackingLabel,  
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {

      })
      .catch((error) => {});
  };
 
  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="divBox" ref={updateRef}>
          <p className="updateTitle">Order {orderID} - Update</p>
          <p className="updateLabel">Order Status</p>
          <input
            id="input2"
            className="updateInput"
            type="text"
            name="username"
            placeholder={orderLabel}
            onKeyDown={(event) => {
              if (event.keyCode === 13) confirmData();
            }}
          />
          <p className="updateLabel">Tracking Link</p>
          <input
            id="input2"
            className="updateInput"
            type="text"
            name="username"
            placeholder={trackingLabel}
            onKeyDown={(event) => {
              if (event.keyCode === 13) confirmData();
            }}
          />
          <button
            id="loginButton"
            className="updateButton"
            onClick={confirmData}
          >
            Confirm Change
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateSale;
