/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { url_path, check, register, copyrightLabel, confirmInvite} from "./../extension/exportedStrings";
import { validateEmail, validateUsername } from "./../extension/inputValidity";
import { createAcc } from "./../extension/registerAccount";
import { registerLink } from "./../extension/registerLink";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import FooterComponent from "./Footer.js";

import "./../style/register.css";
const RegisterInvite = () => {
  useEffect(() => {
    document.title = "SociRank - Register"; // Change the title here

    console.log(unique_link)
  }, []);

  const location = useLocation();
  const currentPath = location.pathname;

  const part = currentPath.split("/");
  const unique_link = part[2];

  
  const navigate = useNavigate();

  const [button, setButton] = useState("Register");

  var validArray = [false, false, false, false, false];
  var usernameValue; var emailValue; var passwordValue; var dobValue; var fullnameValue;

  const detailCheck = async (value, type) => {
    if(type == "username"){
        if(!validateUsername(value)){
           const item = document.getElementById(type);
           item.style.borderColor = "red";

           return;
        }
    }else if(type == "email"){
        if(!validateEmail(value)){
           const item = document.getElementById(type);
           item.style.borderColor = "red";

           return;
        }
    }
    const url =  url_path + check; 
    const data = {
      username: value, // Replace with the actual username
      type: type, 
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // Handle the response data here
        if(data == "success"){
          const item = document.getElementById(type);
          // Change the border color to red
          item.style.borderColor = "#AFE1AF";

          if(type == "username"){
            validArray[0] = true
            usernameValue = value.trim();
          }else{
            validArray[1] = true
            emailValue = value.trim();
          }
        }else{
          const item = document.getElementById(type);
          // Change the border color to red
          item.style.borderColor = "red";
          if(type == "username"){
            validArray[0] = false
          }else{
            validArray[1] = false
          }
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  const fullnameCheck = async (value) => {
    if(value.trim() == ""){
      const item = document.getElementById("fullname");
      // Change the border color to red
      item.style.borderColor = "red";
      validArray[4] = false
    }else{
      const item = document.getElementById("fullname");
      // Change the border color to red
      item.style.borderColor = "#AFE1AF";
      fullnameValue = value.trim();
      validArray[4] = true
    }
  }

  const passwordCheck = async (value) => {
    if(value.length < 8){
      const item = document.getElementById("password");
      // Change the border color to red
      item.style.borderColor = "red";
      validArray[2] = false
    }else{
      const item = document.getElementById("password");
      // Change the border color to red
      item.style.borderColor = "#AFE1AF";
      passwordValue = value;
      validArray[2] = true
    }
  }

  const dobCheck = async () => {
    const dob1 = document.getElementById("dob1");
    const dob2 = document.getElementById("dob2");
    const dob3 = document.getElementById("dob3");

    if(dob1.value.trim() != "" && dob2.value.trim() != "" && dob3.value.trim() != ""){
      const item = document.getElementById("dob1").style.borderColor = "#AFE1AF";
      const item2 = document.getElementById("dob2").style.borderColor = "#AFE1AF";
      const item3 = document.getElementById("dob3").style.borderColor = "#AFE1AF";
      // Change the border color to red
      validArray[3] = true
      dobValue = dob1.value.trim() + "/" + dob2.value.trim() + "/" + dob3.value.trim();
    }else if(dob1.value.trim() == ""){
      const item = document.getElementById("dob1");
      // Change the border color to red
      item.style.borderColor = "red";
      validArray[3] = false
    }else if(dob2.value.trim() == ""){
      const item = document.getElementById("dob2");
      // Change the border color to red
      item.style.borderColor = "red";
      validArray[3] = false
    }else if(dob3.value.trim() == ""){
      const item = document.getElementById("dob3");
      // Change the border color to red
      item.style.borderColor = "red";
      validArray[3] = false
    }
  }

  const createAccount = async () => {
    if (validArray.every(Boolean)) {
       setButton("Creating account...")
       const url = url_path + register; 
       const url2 = url_path + confirmInvite; 
       createAcc(url, usernameValue, fullnameValue, emailValue, passwordValue, dobValue)
       registerLink(url2, unique_link)

       const authenticationData = {
        username: usernameValue,
      };

      // Save the data to localStorage
      localStorage.setItem('currentUser', JSON.stringify(authenticationData));

      let path = `/`;
      navigate(path);
    }
  }

  return (
    <div>
      <div className="registerBackground">
        <div className="divBox">
          <img src={app_icon2} className="divImage" width="25" height="25" />
          <input
            className="username"
            id="username"
            name="username"
            placeholder="Enter username"
            onBlur={(e) => detailCheck(e.target.value, "username")}
            autoFocus
          ></input>
          <input
            className="fullname"
            id="fullname"
            name="fullname"
            placeholder="Enter fullname"
            onBlur={(e) => fullnameCheck(e.target.value)}
          ></input>
          <input
            className="email"
            id="email"
            type="email"
            name="email"
            placeholder="Enter email"
            onBlur={(e) => detailCheck(e.target.value, "email")}
          ></input>
          <input
            id="password"
            class="password"
            name="password"
            type="password"
            placeholder="Enter password"
            onBlur={(e) => passwordCheck(e.target.value)}
          />

          <div id="date-input" className="date-input" onBlur={(e) => dobCheck()}>
            <input id="dob1" type="number" placeholder="Day" min="1" max="31" />
            <input id="dob2" type="number" placeholder="Month" min="1" max="12" />
            <input id="dob3" type="number" placeholder="Year" min="1900" max="2099" />
          </div>

          <p id="tosLabel" class="regtosLabel">
            By clicking register, you agree that you have read and accepted the
            <a href="/terms-condition" className="link" target="_blank">
              {" "}
              Terms of Use
            </a>
          </p>

          <button id="registerButton" class="regButton" onClick={createAccount}>
            {button}
          </button>
        </div>
        <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterInvite;
