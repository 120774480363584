/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import { url_path, check, copyrightLabel } from "./../extension/exportedStrings";
import { validateEmail } from "./../extension/inputValidity";

import "./../style/forgotpassword.css";
import FooterComponent from "./Footer.js";

import CryptoJS from 'crypto-js';
import { googleLoginSecretKey } from "./../extension/exportedStrings";
function ForgotPassword() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "SociRank - Login";
  }, []);

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  var emailOk = false
  var emailValue = ""

  const detailCheck = () =>{
    const email = document.getElementById("email");

    if(!validateEmail(email.value.trim())){
        email.style.borderColor = "red";

        return;
    }
    const url =  url_path + check; 
    const data = {
        username: email.value.trim(), // Replace with the actual username
        type: 'email', 
      };

    fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          // Handle the response data here
          if(data == "success"){
            // Change the border color to red
            email.style.borderColor = "#AFE1AF";

            emailOk = true;
            emailValue = email.value.trim();
          }else{
            // Change the border color to red
            email.style.borderColor = "red";
            emailOk = false
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
  }
  const sendEmail = () => {
    if(emailOk){
      setButtonDisabled(true);
      const bottomLabel = document.getElementById("bottomLabel");
      bottomLabel.style.visibility = "visible"
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press here
      sendEmail();
    }
  };

  return (
    <div>
      <div className="createBackground">
        <div className="divBox">
          <img src={app_icon2} className="divImage" width="25" height="25" />
          <p className="createLabel">Enter email of the account you wish to recover</p>
          <input
            className="createInput"
            id="email"
            name="email"
            placeholder="Enter email"
            onBlur={(e) => detailCheck(e.target.value, "username")}
            onKeyPress={handleKeyPress}
            autoFocus
          ></input>

          <p className="bottomLabel" id="bottomLabel">
            Email has been sent
          </p>
          <button class="createButton" onClick={sendEmail} disabled={isButtonDisabled}>
            Send reset link to email
          </button>
        </div>
        <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
      </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
