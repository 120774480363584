/** @format */
import React, { useEffect, useState } from "react";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import { app_icon2, profile_icon } from "./../extension/exportedIcons";
import { app_icon, soci_coin } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import "./../style/notification.css";

const Notifcation = () => {
  useEffect(() => {
    document.title = "SociRank - Notification"; // Change the title here
  }, []);

  const temp = []
  return (
    <div>
        <MinibarComponent />

        <div className="notificationDiv">
            {temp.length === 0 ? (
              <p className="noDataLabel">You currently have no notifications</p>
            ) : (
              temp.map((array, index) => (
                <div className="notificationTable" key={index}>
                  <img src={profile_icon} className="notifIcon" width="40" height="40" />
                  <div class="notifInfoDiv">
                    <p className="notifDivLabel">Username</p>
                    <p className="notifDivLabel2">Random</p>
                  </div>
                </div>
              ))
            )}
        </div>

        <div className="footerDiv2">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
        </div>
    </div>
  );
};

export default Notifcation;
