/** @format */
import React, { useEffect, useState } from "react";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import { app_icon2, profile_icon } from "./../extension/exportedIcons";
import { app_icon, soci_coin } from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import { top_icon, hoodie_icon, pants_icon } from "./../extension/merchImg";

import "./../style/checkout.css";

const Checkout = () => {
  useEffect(() => {
    document.title = "SociRank - Checkout"; // Change the title here
  }, []);

  const cartArray = []
  return (
    <div>
        <MinibarComponent />

        <div className="checkoutDiv">
          <table className="checkoutTable">
            <tbody>
              {cartArray.map((carts, index) => (
                <tr className="checkoutRow">
                  <div className="checkoutHeader">
                    <div className="checkoutHeaderDiv">
                      <div className="checkoutHeaderDivLeft">
                        <div className="checkoutHeaderLabel">
                          <p className="checkoutLabel">Availability</p>
                          <p className="checkoutLabel2">In stock</p>
                        </div>
                        <div className="checkoutHeaderLabel">
                          <p className="checkoutLabel">Price</p>
                          <p className="checkoutLabel2">$0</p>
                        </div>

                        <div className="checkoutHeaderLabel">
                          <p className="checkoutLabel">Quantity</p>
                          <p className="checkoutLabel2">0</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="checkoutBody">
                    <div className="checkoutBodyDivLeft">
                      <img
                        id="checkoutIcon"
                        className="checkoutIcon"
                        src={top_icon}
                      ></img>

                      <div className="checkoutTextDiv">
                        <p
                          className="checkoutUsername"
                        >
                        "eh"
                        </p>
                      </div>
                    </div>

                    <div className="checkoutBodyDivRight">
                      <button className="checkoutUpdate">Delete</button>
                      <button className="checkoutSelected">Selected</button>
                    </div>
                  </div>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="checkoutPurchaseDiv">
          <div className="checkPurchaseLabels">
            <p className="checkPurchaseLabel">Checkout will be available soon</p>
            {/* <p className="checkPurchaseLabel2">Whever</p> */}
            {/* <p className="checkPurchaseLabel3">Change delivery address</p> */}
          </div>
          {/* <div className="checkPurchaseLabels">
            <p className="checkPurchaseLabel2Alt">Total items: 0</p>
          </div>

          <div className="checkPurchaseLabels">
            <p className="checkPurchaseLabel2Alt">Total price: $0</p>
          </div>

          <button className="checkPurchaseButton">Buy now</button> */}

        </div>

        <div className="footerDiv2">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
        </div>
    </div>
  );
};

export default Checkout;
